import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { HttpClientModule, HttpBackend } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppComponent } from './app-toll.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { FileSaverModule } from 'ngx-filesaver';
import { CommonModule, DatePipe } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './services/matPaginatorIntl';
import 'hammerjs';

//tables
import { TableComponentModule } from './components/table/table.component.module';
import { TableExtractionComponentModule } from './components/table-extraction/table-extraction.component.module';
import { TableSettingsComponentModule } from './components/table-settings/table-settings.component.module';
import { TablePopupInputComponentModule } from './components/table/table-popup-input/table-popup-input.component.module';
import { TableTdComponentModule } from './components/table/table-td/table-td.component.module';

//pages & components
import { GroupByPipeModule } from './pipes/group-by-pipe/group-by.pipe.module';
import { OrderByPipeModule } from './pipes/order-by-pipe/order-by.pipe.module';
import { FilterPipeModule } from './pipes/filter-pipe/filter.pipe.module';
import { DateFormatPipeModule } from './pipes/date-format/date-format.pipe.module';
import { DateFormatPlaceholderPipeModule } from './pipes/date-format-placeholder-pipe/date-format-placeholder.pipe.module';
import { CurrencyFormatPipeModule } from './pipes/currency/currency.pipe.module';
import { MotivationPromptComponentModule } from './components/motivation-prompt/motivation-prompt.component.module';
import { AddRolePromptComponentModule } from './components/add-role-prompt/add-role-prompt.component.module';
import { TollCommonService } from './services/toll-common.service';
import { DashboardControlComponentModule } from './components/dashboard-control/dashboard-control.component.module';
import { NumberSeparatorPipeModule } from './pipes/number-separator/number-separator.pipe.module';
import { InputPromptComponentModule } from './components/input-prompt/input-prompt.component.module';
import { AddressFormComponentModule } from './components/address-form/address-form.component.module';
import { ChangeCompanyromptModule } from './components/change-company-prompt/change-company-prompt.component.module';


export function initializeApp(commonService: TollCommonService) {
  return (): Promise<any> => {
    return commonService.startup();
  };
}


@NgModule({

  declarations: [
    AppComponent
  ],
  imports: [
    FileSaverModule,
    BrowserModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md'
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    IonicStorageModule.forRoot(),
    FormsModule,
    CommonModule,
    TableComponentModule,
    TableSettingsComponentModule,
    TableExtractionComponentModule,
    TablePopupInputComponentModule,
    TableTdComponentModule,
    GroupByPipeModule,
    OrderByPipeModule,
    FilterPipeModule,
    DateFormatPipeModule,
    DateFormatPlaceholderPipeModule,
    CurrencyFormatPipeModule,
    MotivationPromptComponentModule,
    AddRolePromptComponentModule,
    DashboardControlComponentModule,
    NumberSeparatorPipeModule,
    InputPromptComponentModule,
    AddressFormComponentModule,
    ChangeCompanyromptModule
  ],
  providers: [
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    }, {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TollCommonService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }




// required for AOT compilation
export function HttpLoaderFactory(http: HttpBackend) {
  let translationsUrl: string;
  if (document.baseURI.indexOf('/test-') != -1 || document.baseURI.indexOf('/localhost') != -1)
    translationsUrl = 'https://safomf-mfeassets.azurewebsites.net/mfe-assets/i18n/';
  else if (document.baseURI.indexOf('/stage.') != -1 || document.baseURI.indexOf('/stage-') != -1)
    translationsUrl = 'https://safomf-mfeassets-stage.azurewebsites.net/mfe-assets/i18n/';
  else
    translationsUrl = 'https://safomf-mfeassets-prod.azurewebsites.net/mfe-assets/i18n/';
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
    { prefix: translationsUrl, suffix: ".json" }
  ]);
}

/*// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}*/

/*// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" }
  ]);
}*/